@import './../../../themes.scss';

.resume-template-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  text-align: center;

  .profile-photo-container {
    cursor: pointer;
    margin-bottom: 10px;

    img {
      display: block;
      margin: 0 auto;
    }
  }
  h1, p {
    margin: 0;
  }
}


.timeline-item {
  align-items: flex-start;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: #ccc;
    left: -4px;
    top: 20px;
  }

  &:last-child:before {
    height: 80%;
  }

  .timeline-dot {
    width: 8px;
    height: 8px;
    background: var(--var-color);
    border-radius: 50%;
    position: absolute;
    left: -7px;
    top: 20px;
  }

  .timeline-content {
    .timeline-header {
      padding-top: 10px;
      display: flex;
      margin: 0 10px;
      .timeline-year {
        font-weight: bold;
        color: var(--var-color);
        font-size: 0.6em;
        margin-top: 5px;
        margin-left: auto;
      }
    }
    .timeline-sub-header{
      display: flex;
      margin: 0 10px;
      span:last-child {
        margin-left: auto;
      }
    }
    .item-title{
      font-weight: bold;
      margin: 8px 5px 0;
    }
    .item-list {
      padding-left: 20px;
    }
  }
}
.rounded-container {
  border: 1px solid var(--var-color); /* Subtle border */
  border-radius: 8px; /* Rounded corners for a softer look */
  padding: 5px; /* Increased padding for better spacing */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid var(--var-color);
}
.sub-header-h6{
  display: flex;
  justify-content: left;
  margin: 0 10px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--var-color);
  padding-bottom: 3px;
}
.resume-template-page {
  font-family: var(--resume-font-family) !important;
  padding: 0.0in;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 10px;
}


.editable {
  cursor: text;
  text-align: start;
  &.editing {
    padding: 5px;
    outline: 2px dashed #999; /* Dashed outline to indicate editing */
    opacity: 0.9;
    cursor: text; /* Change the cursor to text */
    box-shadow: none !important; /* Remove box shadow during editing */
    transform: none !important; /* Reset the scale transformation */
  }
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
}

.sortable-list{
  list-style-type: none !important;
  padding-left: 0px;
}

.dragHandle {
  cursor: grab;
  position: absolute;
  right: 0;
  user-select: none; /* Prevent text selection while dragging */

}
.hide-drag-handles {
  .dragHandle{
    display: none;
  }
}
.sortableHelper {
  font-weight: bolder;
  list-style: none;
  color: var(--var-color); //#f509f5; //
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: auto;
  margin-right: auto;
}



.skill{
  display: flex;
  align-items: center;
  .skill-name {
    padding-left: 10px;
  }
  .skill-bar {
    margin-left: auto;
    background-color: var(--var-color);
    color: var(--var-color-bg);
    border-radius: 5px;
    height: 4px;
  }
}


.sortable-item{
  span{
    padding-left: 10px;
  }
}