.preferenceItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  max-width: 450px;

  label {
    margin-bottom: 0;
    margin-right: 10px;
    flex: 1; /* Allow label to take as much space as needed */
  }

  input, select {
    flex: 2; /* Allow input to take twice the space compared to the label */
    padding: 5px;
    margin-bottom: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input[type="checkbox"] {
    width: 50px;
    margin: 0px;
  }
  div{
    padding: 2px;
  }
}

.inactive {
  display: none;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.active {
  display: block !important;
  opacity: 1 !important;
  padding: 10px;
  border-radius: 5px;
  position: relative; /* Ensure it stays within the container */
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: auto;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  input{
    max-width:100px;
  }
}
