
.ResumeSection {
  position: relative;
  //padding: 16px;
  //border: 1px solid #007bff;
  border-radius: 8px;
  padding: 3px;
  //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ResumeSection:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  &.spacer-section{
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  }
}

.ResumeSection .header .title {
  font-size: 1.2em;
  font-weight: bold;
  color: #007bff;
  display: flex;
  align-items: center;
}

.ResumeSection .header .title img {
  margin-right: 8px;
}

.ResumeSection .header .rename {
  font-size: 0.9em;
  color: #007bff;
  cursor: pointer;
}

.ResumeSection .actions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ResumeSection .actions .action {
  margin: 4px 0;
  font-size: 1.2em;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.ResumeSection .actions .action:hover {
  color: #0056b3;
}



.ReactModal__Overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

.ReactModal__Content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  //min-width: 30vw;
  //max-width: 80vw;
  background: rgba(248, 252, 251, 0.75);
  padding: 10px;
  border-radius: 10px;
  border: 2px solid rgb(0, 0, 0);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  z-index: 1100;

  max-height: 95vh;
  overflow-y: auto;

  h2, input
  {
    margin-top: 0;
    background: white;
  }
}


