.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.presetSelector {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
    font-weight: bold;
  }

  select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
}
