.config-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  min-height: 800px;

  .actions {
    display: flex;
    justify-content: space-between;

    button {
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background-color: #45a049;
      }

      &:last-child {
        background-color: #008CBA;

        &:hover {
          background-color: #007bb5;
        }
      }
    }
  }

  .page {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
  }

  .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h3 {
      margin: 0;
    }


  }

  .columns {
    display: flex;
    justify-content: space-between;
  }

}
