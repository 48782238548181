.column {
  flex: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  select {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-bottom: 10px;
      background-color: #f9f9f9;
      cursor: pointer;
      position: relative;
      transition: background-color 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        background-color: #f0f0f0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        margin-bottom: 0;
      }

      span {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: bold;
        margin-bottom: 5px;
      }

      button {
        background-color: rgba(163, 4, 4, 0.63);
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 5px 10px;
        cursor: pointer;
        margin-top: 10px; /* Added margin for spacing */
        align-self: flex-end;

        &:hover {
          background-color: rgba(137, 5, 5, 0.66);
        }
      }
    }
  }
}
